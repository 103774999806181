<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-3 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Item Details</label>
                        <p>{{ data.product?.name }}</p>
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-1 col-12">
                        <div class="mb-1">
                          <label class="form-label" for="product_id">UoM</label>
                          <p>{{ data.product?.description?.unit?.name }}</p>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">
                              Order Quantity
                            </label>
                            <p>{{data.quantity}}</p>
                        </div>
                    </div>
                </template>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-percent">Delivered Quantity</label>
                        <p>{{ data.delivered_qty }}</p>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-amount">Remaining Quantity</label>
                        <p>{{remainingQuantity}}</p>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Delivery Quantity ({{productStock ?? 0}})</label>
                        <vField
                            v-model="data.delivery_qty"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            placeholder="Please enter delivery qty"
                            :max="remainingQuantity"
                            :disabled="remainingQuantity === 0 || viewOnly"
                            @blur="validateQuantity"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Business Unit</label>
                        <v-select
                            placeholder="Select Business"
                            v-model="data.business_id"
                            :options="business"
                            label="name"
                            :reduce="name => name.id"
                            :disabled="viewOnly"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Project</label>
                        <v-select
                            placeholder="Select Project"
                            v-model="data.project_id"
                            :options="projects"
                            label="name"
                            :reduce="name => name.id"
                            :disabled="viewOnly"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Cost Centre</label>
                        <v-select
                            placeholder="Select Cost Centre"
                            v-model="data.cost_centre_id"
                            :options="costCentres"
                            label="name"
                            :reduce="name => name.id"
                            :disabled="viewOnly"
                        />
                    </div>
                </div>

                <div class="col-md-6 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                        <vField
                          style="height: 38px"
                          as="textarea"
                          name="description"
                          v-model="data.description"
                          class="form-control"
                          :disabled="viewOnly"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import figureFormatter from '@/services/utils/figureFormatter'
import {inject, onMounted, watch} from "vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const {commaFormat} = figureFormatter()
const showError = inject('showError');
const route = useRoute();
const selectedProduct = ref({});
const productStock = ref(null);
const {fetchProductStock} = handlePurchaseAndSales();

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  products: Array,
  accountHeads: Array,
  business: Array,
  projects: Array,
  costCentres: Array,
  locationId : {
      type: Number,
      default: null
  },
  viewOnly : {
      type: Boolean,
      default: false
  }
})

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
     if(!pr.isItem) {
         return pr.data.amount
     }
})

const productId = computed(() => pr.data.product_id)
const remainingQuantity = computed(() => pr.data.quantity - pr.data.delivered_qty)

//methods
const validateQuantity = () => {
    if(selectedProduct.value.product_type === 'services' ) {
        return;
    }

    if(!pr.data.delivery_qty || pr.data.delivery_qty <= 0 ){
      showError('Delivery quantity cannot be empty or 0!')
      return;
    }

    const remainingQty = pr.data.quantity - pr.data.delivered_qty;

    if(productStock.value === null || pr.data.delivery_qty > productStock.value) {
        showError('Delivery quantity cannot be more than product stock!')
        pr.data.delivery_qty = productStock.value ?? 0;
        return;
    }

    if(remainingQty === 0 || pr.data.delivery_qty > remainingQty) {
        showError('Delivery quantity cannot be more than remaining quantity!')
        pr.data.delivery_qty = remainingQty ?? 0;
    }
}

const locationId = computed(() => pr.locationId)

watch(locationId, (newValue, oldValue) => {
  productStock.value = null;
  fetchStock();
})
//method
const fetchStock = () => {
  if (productId.value !== null && productId.value != undefined) {
    let query = `?company_id=${route.params.companyId}`;
    query += ((locationId.value !== null) ? `&location_id=${locationId.value}` : '');
    fetchProductStock(productId.value, query).then(res => {
      productStock.value = null;
      if (res.status === false) {
        showError(res.message);
        return;
      }
      productStock.value = res.data.stock;
    })
  }
}

onMounted(() => {
  //check if the product is a service
  if (pr.products.length > 0 && productId.value) {
    selectedProduct.value = pr.products.find(prod => prod.id === productId.value)
    if(selectedProduct.value.product_type === 'services') {
      productStock.value = null;
      return;
    }
    fetchStock();
  }
})

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

